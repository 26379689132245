let buttons = document.querySelectorAll("[name='do']");
buttons.forEach(button => {
    button.addEventListener("click", function(event) {
        let form = button.closest("form");
        let honeypotField = form.querySelector('input[name="telephone"]');

        if(honeypotField) {
            honeypotField.removeAttribute("required");
        }
    });
});