// Select all elements with the class 'icon'
const icons = document.querySelectorAll('.icon');

// Loop through each icon
icons.forEach(function(icon) {
    // Get the computed font size of the parent element
    const fontSize = window.getComputedStyle(icon.parentElement, null).getPropertyValue('font-size');

    // Set the width and height of the ::before pseudo-element to the computed font size
    icon.style.setProperty('--icon-size', fontSize);
});