let activeModals = [];
let eventHandlersAttached = {}; // Add this line to keep track of which modals have had their event handlers attached
const parser = new DOMParser();

export function initializeModals() {
    document.querySelectorAll('[data-toggle="modal"]').forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            openModal(button.getAttribute('data-target'));
        });
    });

    document.querySelectorAll('[data-dismiss="modal"]').forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            closeActiveModal();
        });
    });

    window.addEventListener("keydown", (e) => {
        if (e.defaultPrevented) {
            return; // Do nothing if the event was already processed
        }

        if (e.key === "Esc" || e.key === "Escape") {
            e.preventDefault();
            closeActiveModal();
        }
    });

}

export function openModal(dialogSelector) {
    const dialog = document.querySelector(dialogSelector);

    dialog.showModal();
    dragElement(dialog);
    activeModals.push(dialog);
    document.body.style.overflow = 'hidden';

    const currentModal = dialog; // currentModal is the same as dialog in this context

    let form = currentModal.querySelector('form[data-validate]');
    if (form) {
        // Check if the event handler has already been attached for this modal
        if (!eventHandlersAttached[dialogSelector]) {
            form.addEventListener('submit', (e) => {
                e.preventDefault();

                let formData = new FormData(form); // notice "form" instead of "this"
                formData.append('do', currentModal.querySelector('button[name="do"]').value);

                let fetchOptions = {
                    method: "POST",
                    body: formData,
                    credentials: "same-origin",
                    headers: {"Accept": "text/html"}
                };

                fetch(location, fetchOptions)
                    .then(response => response.text())
                    .then(html => {
                        updateDocument(html, currentModal); // pass currentModal to updateDocument
                    });
            });

            // Mark the event handler as attached for this modal
            eventHandlersAttached[dialogSelector] = true;
        }
    }
}
export function closeActiveModal() {
    const activeModal = activeModals.pop();
    if (activeModal) {
        let forms = activeModal.getElementsByTagName("form");
        if (forms.length > 0) {
            for (let form of forms) {
                let previews = form.querySelector('#previewContainer');
                if (previews) {
                    previews.innerHTML = '';
                }
                form.reset();
            }
        }

        // TODO: Forms do not reset after errors in data-validate

        activeModal.setAttribute('closing', "");

        activeModal.addEventListener('animationend', () => {
            activeModal.removeAttribute('closing');
            activeModal.close();
        }, {once: true});

        if (activeModals.length === 0) {
            document.body.style.overflow = '';
        }
    }
}

function updateDocument(html, currentModal) {
    let newDocument = parser.parseFromString(html, "text/html");
    let newForm = newDocument.querySelector(`#${currentModal.id} form[data-validate]`);

    if (newForm) {
        let labels = Array.from(newForm.querySelectorAll('label'));

        if (labels.every(label => !label.getAttribute('data-error'))) {
            closeActiveModal();
            document.body.innerHTML = newDocument.body.innerHTML;
        } else {
            currentModal.querySelector('form[data-validate]').innerHTML = newForm.innerHTML;
        }
        initializeModals();
    }
}

// Call the initializeModals function when the file is loaded
initializeModals();

function dragElement(elmnt) {
    let isDragging = false;
    let offsetX, offsetY;

    // elmnt.addEventListener('mousedown', handleMouseDown);
    elmnt.addEventListener('mousemove', handleMouseMove);
    elmnt.addEventListener('mouseup', handleMouseUp);
    elmnt.addEventListener('click', handleMouseUp);

    if (elmnt.querySelector('.d-modal-header')) {
        // If present, the header is where you move the DIV from
        elmnt.querySelector('.d-modal-header').addEventListener('mousedown', e => {
            handleMouseDown(e);
            e.stopPropagation(); // Prevent propagation to document mousedown
        });
    }

    function handleMouseDown(e) {
        // Check if the clicked element is the close button
        if (e.target.matches('.close-modal')) {
            return; // If it is, don't start dragging
        }

        isDragging = true;

        offsetX = e.clientX;
        offsetY = e.clientY - elmnt.offsetTop;
    }

    // Function to handle mouse move event
    function handleMouseMove(e) {
        if (!isDragging) return;

        const newX = e.clientX - offsetX;
        const newY = e.clientY - offsetY;

        elmnt.style.left = newX + 'px';
        elmnt.style.top = newY + 'px';
    }

    function handleMouseUp() {
        isDragging = false;
    }
}